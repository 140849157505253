.admin-page .rank-list-wrapper{
    width: 100%;
    max-width: 320px;
}
.admin-page .rank-list-item{
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid #555;
    padding: 5px;
}
.admin-page .rank-list-item:hover{
    font-weight: bold;
}
.admin-page .people-count{
    padding: 7px 0;
    margin-left:  -10px;
}
.admin-page .coupon-user-item{
    cursor: pointer;
    border-bottom: 1px solid #555;
    padding: 1rem;
    margin-bottom: 0;
}
.admin-page .coupon-user-item:hover {
    background: #222;
}